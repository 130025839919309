
import {
    defineComponent, PropType, Ref, ref, watch
} from 'vue';
import { account as accountRequest, country } from '@/data/';
import billingDialog from '@/components/view/common/dialog/billing';
import billingDialogIns from '@/components/view/common/dialog/billing-installer';
import { showBillingDialog } from './util';

interface BillInfo {
    BillingTitle: string;
    Contactor: string;
    Street: string;
    Postcode: string;
    City: string;
    Country: string;
    TelePhone: string;
    Fax: string;
    Email: string;
}
type UserType = 'property' | 'propertyCommunity' | 'office'| 'distributorCom' | 'distributorIns' | 'installer' | 'community' | 'distributorOffice';
export default defineComponent({
    components: {
        billingDialog,
        billingDialogIns
    },
    props: {
        userType: {
            type: String as PropType<UserType>
        }
    },
    setup(props, { emit }) {
        const billInfo: Ref<BillInfo> = ref({
            BillingTitle: '',
            Contactor: '',
            Street: '',
            Postcode: '',
            City: '',
            Country: '',
            TelePhone: '',
            Fax: '',
            Email: ''
        });

        const setBillingInfo = (data: BillInfo) => {
            const resultBilling = { ...data };
            country.Location.CountryRegion.forEach((item) => {
                if (item.Code === data.Country) resultBilling.Country = item.Name;
            });
            return resultBilling;
        };

        const getMsg = () => {
            switch (props.userType) {
            case 'community':
            case 'office':
                accountRequest.getInstallerBillingInfo((data: BillInfo) => {
                    billInfo.value = setBillingInfo(data);
                });
                break;
            case 'property':
            case 'propertyCommunity':
                accountRequest.getPropertyBillingInfo((data: BillInfo) => {
                    billInfo.value = setBillingInfo(data);
                });
                break;
            case 'installer':
                accountRequest.getInstallerBillingInfo((data: BillInfo) => {
                    billInfo.value = setBillingInfo(data);
                });
                break;
            case 'distributorCom':
            case 'distributorIns':
            case 'distributorOffice':
                accountRequest.getDistributorBillingInfo((data: BillInfo) => {
                    billInfo.value = setBillingInfo(data);
                });
                break;
            default:
                break;
            }
        };
        getMsg();
        const isShowBill = ref(false);

        watch(billInfo, () => {
            const info = billInfo.value;
            if (info.BillingTitle === '' || info.Contactor === '' || info.Street === '' || info.City === ''
                || info.Postcode === '' || info.Country === '' || info.TelePhone === '' || info.Email === '') {
                emit('billInfoFilled', false);
            } else {
                emit('billInfoFilled', true);
            }
        }, {
            immediate: true
        });

        return {
            billInfo,
            isShowBill,
            getMsg,
            showBillingDialog
        };
    }
});
