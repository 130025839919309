const showBillingDialog = (isShowBill: boolean, userType: string) => {
    let res = '0';
    // project installer dis 付费时账单的弹窗
    if (isShowBill && (userType === 'community' || userType === 'office' || userType === 'installer' || userType === 'distributorIns'
    || userType === 'distributorCom' || userType === 'distributorOffice')) {
        res = '2';
    } else if (isShowBill && userType !== 'community' && userType !== 'office' && userType !== 'installer' && userType !== 'distributorIns'
    && userType !== 'distributorCom' && userType !== 'distributorOffice') {
        // pm付费时账单的弹窗
        res = '1';
    } else {
        res = '0';
    }
    return res;
};

export default null;
export {
    showBillingDialog
};